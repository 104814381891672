<template>
    <div class="hexiaosucess">
        <div class="hexiaosucess-wrap">
            <div class="hexiaosucess-wrap-1">
                <img src="../../../assets/chenggong.png" alt />
                <p>核销成功</p>
            </div>
            <div class="hexiaosucess-wrap-2">
                <p @click="goToPath(0)" :class="{tel:cur==0}">核销记录</p>
                <p @click="goToPath(1)" :class="{tel:cur==1}">返回商家工作台</p>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .hexiaosucess {
        width: 100%;
        height: 100vh;
        background: #f6f6f6;
        position: absolute;
        top: 0;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .hexiaosucess-wrap {
            width: 100%;

            .hexiaosucess-wrap-1 {
                text-align: center;
                margin-top: 0.93rem;

                img {
                    width: 2.91rem;
                    height: 2.35rem;
                }

                p {
                    font-size: 0.3rem;
                    margin-top: 0.53rem;
                }
            }

            .hexiaosucess-wrap-2 {
                width: 5.3rem;
                margin: 0 auto;
                margin-top: 1.56rem;
                font-size: 0.23rem;
                display: flex;

                p {
                    width: 2.38rem;
                    height: 0.86rem;
                    border: 1px solid #dcdcdc;
                    text-align: center;
                    line-height: 0.86rem;
                    margin-right: 0.6rem;
                    color: #aaaaaa;
                }

                p:nth-child(2) {
                    margin-right: 0;
                }

                .tel {
                    color: #e14f2a;
                    border: 1px solid #e14f2a;
                }
            }
        }
    }
</style>
<script>
    export default {
        data() {
            return {
                cur: 0
            };
        },
        methods: {
            goToPath(id) {
                if (id == 0) {
                    this.$router.replace({
                        path: "/hexiaorecords"
                    });
                }
                if (id == 1) {
                    this.$router.replace({
                        path: "/merchantwork"
                    });
                }
            }
        }
    };
</script>
